import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Title, Button, Section, Box, Text, Input } from '../../components/Core';

import imgOval from '../../assets/image/png/l1-cta-oval.png';
import svgCurve from '../../assets/image/svg/l1-curve-cta.svg';

const LeftCard = styled(Box)`
  position: absolute;
  top: 0;
  left: 0px;
`;

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`;

const CTA = () => {
  return (
    <>
      {/* <!-- CTA section --> */}
      <Section bg="light" className="position-relative" style={{ paddingTop: '0px' }}>
        <div className="test-iframe">
          <iframe
            id="JotFormIFrame-210627659803056"
            title="TEMPLATE-TEST"
            src="https://form.jotform.com/210627659803056"
            frameBorder="0"
            style={{
              border: 'none',
              margin: 'auto',
              width: 'auto',
              height: ' 1300px',
              top: 0,
              left: 0,
              minWidth: '100%',
              zIndex: 10,
            }}
            scrolling="yes"></iframe>
        </div>
      </Section>
    </>
  );
};

export default CTA;
