import React from 'react';

import PageWrapper from '../components/PageWrapper';
import { Pages } from '../components/ContentfulContents/contentfulPages';
import { Container } from 'react-bootstrap';
import { Box, Title } from '../components/Core';
import ReactHtmlParser from 'react-html-parser';
import CTA from '../sections/landing1/CTA';

const Form = () => {
  const { prospect } = Pages();

  return (
    <>
      <PageWrapper>
        <Container>
          {prospect[0]?.node?.sectionTitle && (
            <>
              <Title variant="card" key={`${prospect[0]?.node?.id}${prospect[0]?.node?.sectionTitle}`}>
                {prospect[0]?.node?.sectionTitle}
              </Title>
            </>
          )}
          <div className="pt-5" />
          <div>
            <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
              {prospect[0]?.node?.copy?.childmarkdownRemark?.html && (
                <>{ReactHtmlParser(prospect[0]?.node?.copy?.childmarkdownRemark?.html)}</>
              )}
            </Box>
            <CTA />
          </div>
        </Container>
      </PageWrapper>
    </>
  );
};
export default Form;
